const applicationId = 'c2813815-7333-427c-a53b-4ced1ff12f7a';

export const environment = {
    production: false,
    debug: false,
    apiUrl: '/api',
    pdfUrl: 'https://api.prm.homij.tst.recognize.dev/api',
    logo: '/assets/images/logo_homij.svg',
    applicationInsights: {
        instrumentationKey: 'dd66ccee-8033-4a6d-bb6e-a82984e5b93e'
    },
    msAuth: {
        issuer: 'https://login.microsoftonline.com/common/v2.0',
        clientId: applicationId,
        redirectUri: `${window.location.origin}/msal-auth`,
        scopes: [`${applicationId}/.default`],
        redirectToExternalEndSessionEndpointAfterLogout: false,
        redirectRoute: {
            afterAuthentication: ['/'],
            noAuthentication: ['/no-access'],
            failedAuthentication: ['/error']
        },
        // Use LocalStorage to allow same authentication across multiple browser tabs and persistence of tokens
        sessionStorageDriver: localStorage
    },
    features: {
        accessibleSteps: {
            enabled: true,
            showSetToDefaultButton: true // Useful for testing / debugging
        },
        apiProjectCreation: {
            enabled: false
        }
    }
};
